a {
  color: $g-color-base;

  &:hover {
    color: $link-color-hover;
  }

  &:visited {
    color: $link-color-visited;
  }

  &:focus {
    color: $link-color-focus;
  }
}