@import "../sharedConfig.json";
$g-breakpoints: $breakpoint;
// -------------------------------------
//    variables
// -------------------------------------

// -- max-page-width -------------------

$g-max-width: 60rem;
$g-max-width-narrow: 36rem;

// -- document font declarations -------

$doc-font-size: 18px;

// -- branding -------------------------
$g-animation-time-fast: .277s;

$g-color-base: #22a5b5;
$g-color-comp: #D80450;
$g-tertiary-color: #83BF00;
$alt-1: #777777;
$alt-2: #CCCCCC;
$white: #FFFFFF;
$black: #000000;

// -- typography -----------------------

$g-body-font: Arial, Helvetica, sans-serif;
$g-header-font: $g-body-font;

// -- links ----------------------------

$link-color: $g-color-base;
$link-color-hover: darken($link-color, 10);
$link-color-visited: $link-color-hover;
$link-color-focus: darken($link-color-hover, 10);

// -- errors & info --------------------

$success-color: #5CB85C;
$info-color: #5BC0DE;
$warning-color: #F0AD4E;
$danger-color: #D9534F;

// -- labels, copy-classes, alerts -----

$default-color: #999999;
$g-color-muted: #F3F3F3;
$primary-color: #428BCA;

// --- selection colours ---------------

$selection: $link-color;
$selection-text: #FFF;

// -- button colors --------------------

$button-color: $white;
$button-bg-color: $g-color-base;

// -- Border Radius --------------------

$radius-sml: 4px;
$radius-med: 8px;
$radius-lrg: 12px;

// -- spacing -----------------------------

$g-spacing-medium: 1rem;
$g-spacing-tiny: $g-spacing-medium *1/4;
$g-spacing-smaller: $g-spacing-medium *2/4;
$g-spacing-small: $g-spacing-medium * 3/4;

$g-spacing-regular: $g-spacing-medium + $g-spacing-medium * 1/4;
$g-spacing-large: $g-spacing-medium + $g-spacing-medium * 2/4;
$g-spacing-extra-large: $g-spacing-medium + $g-spacing-medium * 3/4;

// - font size
$font--size--1: 2em;
$font--size--2: 1.5em;
$font--size--3: 1.17em;
$font--size--4: 1em;
$font--size--5: .83px;
$font--size--6: .67em;
$font--size--7: 14px;