.l-container {
  max-width: $g-max-width;
  margin: 0 auto;
}

.full-width-container {
  max-width: 100%;
}

.l-container--narrow {
  max-width: $g-max-width-narrow;
}

.l-50-50 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.l-33-33-33 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
