.footer {
  background: $white;
  color: $g-color-base;
  height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;

  &__body{
    display: flex;
    margin: 0 auto;
    width: 300px;

    & li {
      margin: 0 auto;
      list-style: none;
    }

    & a {
      color: $g-color-base;
    }
  }
}