.header {
  background-color: $g-color-base;
  color: $white;
  position: relative;
  padding-bottom: 130px;
  width: 100%;
  margin: 0 auto;

  @include tablet--up {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__wrapper {
    @include tablet--up {
      display: flex;
      justify-content: center;
    }
  }
}

.header__headline {
  padding: $g-spacing-extra-large $g-spacing-extra-large $g-spacing-smaller $g-spacing-extra-large;
  text-align: center;
  font-size: $font--size--2;
  font-weight: normal;

  @include tablet--up {
    font-size: $font--size--1;
    position: relative;
    display: inline-block;
    padding-left: 0;
    padding-right: $g-spacing-medium;
  }
}
