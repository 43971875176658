.logo {
  width: 100%;
  position: absolute;
  text-align: center;

  @include tablet--up {
    width: auto;

    position: relative;
    display: inline-block;
  }
}

.logo img {
  max-width: 280px;
}