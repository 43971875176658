.button {

  $lighten-percent: 10%;

  border: 0;

  background-color: $g-color-base;
  color: choose-contrast-color($g-color-base);

  padding: $g-spacing-smaller $g-spacing-medium;

  text-transform: uppercase;
  font-weight: bold;
  font-size: $doc-font-size;

  transition: background-color $g-animation-time-fast, color $g-animation-time-fast;

  &:hover {
    cursor: pointer;
    background-color: lighten($g-color-base, $lighten-percent);
    color: lighten(choose-contrast-color($g-color-base), $lighten-percent);
  }

  &#{&}--alt-1 {
    background-color: $alt-1;
    color: choose-contrast-color($alt-1);

    &:hover {
      background-color: lighten($alt-1, $lighten-percent);
      color: lighten(choose-contrast-color($alt-1), $lighten-percent);
    }
  }

  &#{&}--alt-2 {

    background-color: $alt-2;
    color: choose-contrast-color($alt-2);

    &:hover {
      background-color: lighten($alt-2, $lighten-percent);
      color: lighten(choose-contrast-color($alt-2), $lighten-percent);
    }
  }

  &#{&}--small {

    padding: $g-spacing-tiny $g-spacing-small;
    font-size: $font--size--7;
  }

}