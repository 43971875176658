$background-color: $white;

html {
  height: 100%;
}

body {
  background-color: $background-color;
  color: choose-contrast-color($background-color);

  font: 100%/1.5 $g-body-font;
  font-size: $doc-font-size;
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  min-height: 100%;
  position: relative;
}
