.message-container {
  margin-top: 220px;
  padding-bottom: ($g-spacing-extra-large)*3;

  @include tablet--up {
    margin-top: 270px;
  }

  &__board {
    margin: 0 auto;
    margin-top: -60px;
    padding: $g-spacing-large;
    background: $g-color-base;
    color: $white;
    text-align: center;
    width: 90%;
    height: auto;
    border-radius: 20px;
    max-width: 500px;

    @include tablet--up {
      padding: $g-spacing-extra-large;
    }

    & h2 {
      font-weight: normal;
    }
  }
}

.targetgroup {
  width: 100%;
  z-index: -1;
  position: absolute;
  text-align: center;
  margin-top: -140px;

  @include tablet--up {
    margin-top: -170px;
  }
}

.targetgroup img {
  max-width: 280px;

  @include tablet--up {
    max-width: 360px;
  }
}
