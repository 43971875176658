/*
html {
  font-family: $g-body-font;
  font-size: $doc-font-size;
  line-height: $doc-line-height;
}
*/

h1 .h1, h2 .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-family: $g-header-font;
  font-weight: 600;
}
