.imprint__padding {
  padding-top: $g-spacing-large;
  padding-bottom: $g-spacing-medium;
  font-size: $font--size--2;
  color: $g-color-base;
}

.imprint__list {
  list-style: none;

  & li:nth-child(5) {
    padding-top: $g-spacing-small;
  }
}

.imprint__list--one-line {
  float: left;
  padding-right: $g-spacing-tiny;
}

.container__legal {
  padding: $g-spacing-large $g-spacing-extra-large;
  padding-bottom: ($g-spacing-large)*3;
}